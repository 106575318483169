

.Header {
  padding: 3%;
  padding-bottom: 0;
  display: flex;
  margin: 0 1%;
  position: fixed;
  z-index: 20;
  width: 100%;
}
.Border-check {
  border: 1px solid yellow;
}

.Logo {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  object-fit: cover;
  animation: logo-spin infinite 20s linear;
}

.Links-container {
  display: flex;
  height: inherit;
  align-items: center;
}

.Link-container {
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5%;
}
.Links-link {
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 1.1em;
  padding-bottom: 10px;
}

.Link-active {
  font-weight: bold;
}

.Link-line {
  height: .25rem;
  margin-bottom: 0;
  background: tomato;
  border: none;
  animation: line 500ms ease-in-out forwards;
}

.Link-line-hide {
  height: .25rem;
  margin-bottom: 0;
  border: none;
}

.LanguageContianer {
  position: absolute;
  right: 10%;
  bottom: .5em;
}

.LanguageFlag {
  width: 5vh;
  height: 5vh;
  cursor: pointer;
  margin-right: 1em;
  border-radius: 30%;
}

@keyframes logo-spin {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes line {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 500px) {

  .Links-link {
    font-size: .9em;
  }
  .Link-container{
    margin-left: .5em;
    margin-right: .5em;
  }
  .LanguageContianer{
    position: absolute;
    right: 8%;
    bottom:1.5em;
  }
  .LanguageFlag{
    width: 4vh;
    height: 4vh;
  }

}

@media only screen and (max-width: 360px) {
    .Links-link {
        font-size: .8em;
      }
      .Link-container{
        margin-left: .4em;
        margin-right: .4em;
      }
      .LanguageContianer{
        position: absolute;
        right: 6%;
        bottom:1.5em;
      }
      .LanguageFlag{
        width: 4vh;
        height: 4vh;
      }
}

@media only screen and (max-width: 300px) {
  .Links-link {
      font-size: .8em;
    }
    .Link-container{
      margin-left: .4em;
      margin-right: .4em;
    }
    .LanguageContianer{
      position: absolute;
      right: 6%;
      bottom:1.5em;
    }
    .LanguageFlag{
      width: 4vh;
      height: 4vh;
    }
}