.Name-container {
    position: absolute;
    left: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 20%;
 }
 
 .Name-container span {
    font-size: 5em;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
 }
 
 .Name-profession {
    margin: 0;
    color: white;
    font-family: 'Raleway', sans-serif;
    margin-left: 25%;
    width: 100%;
 }
 
 .Name-Margin-Lastname {
    margin-left: 25%
 }
 .Avatar-container{
    display: flex;
    justify-content: center;
 }
 .Avatar {
   width: 120px;
   height: 120px;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   border-radius: 50%;
   object-fit: cover;
}

 
 @media only screen and (max-width: 812px) {
    .Name-container span {
       font-size: 3em;
    }
    .Name-profession {
       font-size: 0.8em;
    }
 }
 
 @media only screen and (max-width: 600px) {
    .Name-container span {
       font-size: 3em;
    }
    .Name-profession {
       font-size: 1em;
    }
    .Name-Margin-Lastname {
       margin-left: 15%
    }
 }
 
 @media only screen and (max-width: 500px) {
    .Name-container {
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .Name-container span {
       font-size: 2.7em;
    }
    .Name-profession {
       font-size: 1em;
    }
 }

 @media only screen and (max-width: 360px) {
   .Name-container span {
      font-size: 2em;
   }
   .Name-profession {
      font-size: .8em;
   }
}